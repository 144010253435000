<template>
  <div class="d-flex justify-content-center">
    <div class="d-print-none w-100" v-if="type === 'text'">
      <div
        v-if="file.content_raw"
        ref="rawContent"
        class="embedded-raw-content"
      ></div>
      <HMVueEditorPreview v-else :html="file.content" />
    </div>
    <div
      class="ratio ratio-4x3 d-print-none"
      v-if="type === 'pdf'"
    >
      <iframe ref="pdf-embed" :src="src" type="application/pdf"></iframe>
    </div>
    <div
      class="ratio ratio-4x3 d-print-none"
      v-if="type == 'docx'"
    >
      <iframe :src="src"></iframe>
    </div>
    <audio
      :src="src"
      controls
      :controlsList="allowAudioDownloads ? '' : 'nodownload'"
      v-if="type == 'audio'"
      class="w-100 d-print-none"
      @contextmenu="e => preventContextMenu(e, !allowAudioDownloads)"
    ></audio>
    <div
      class="ratio ratio-16x9 d-print-none"
      v-if="['recording', 'storage_video'].includes(type)"
    >
      <video
        class="embed-video"
        :src="src"
        width="100%"
        controls
        :controlsList="allowRecordingDownloads ? '' : 'nodownload'"
        :preload="preload"
        @contextmenu="e => preventContextMenu(e, !allowRecordingDownloads)"
        @canplay="event => $emit('videoPlayerReady', event.target)"
        @loadeddata="event => $emit('videoPlayerLoaded', event)"
        @ended="event => $emit('videoPlayerEnded', event)"
        @pause="event => $emit('videoPlayerPaused', event)"
        @timeupdate="event => $emit('videoPlayerTimeupdate', event)"
        @seeking="event => $emit('videoPlayerSeeking', event)"
        @seeked="event => $emit('videoPlayerSeeked', event)"
        @durationchange="event => $emit('videoPlayerDurationChanged', event)"
      ></video>
    </div>
    <div
      class="processing d-print-none"
      v-if="type == 'vimeo' && videoInProgress"
    >
      <div class="soft-16-9"></div>
      <div v-if="!videoUploadFailed" class="col-12 col-md-6">
        <div>
          <img class="img-fluid" src="/assets/images/sitting-3.svg" />
        </div>
        <h4 class="mt-4">
          {{ videoProcessingText || $t('GENERAL.TEXT.VIDEO_IS_PROCESSING') }}
        </h4>
        <HMSpinner class="spinner mt-4" />
      </div>
      <div v-else class="col-12 col-md-6">
        <div>
          <i class="fa fa-video-slash h1 c-charcoal"></i>
        </div>
        <h4 class="mt-4">
          {{ $t('GENERAL.TEXT.VIMEO_VIDEO_UPLOAD_FAILED') }}
        </h4>
      </div>
    </div>
    <div
      class="ratio ratio-16x9 d-print-none"
      v-if="type == 'vimeo' && !videoInProgress"
    >
      <ClientOnly>
        <vueVimeoPlayer
          id="vimeo-player"
          width="100%"
          height="100%"
          :video-url="`${src}?nocache=${vimeoPlayerKey}`"
          :video-id="null"
          :autoplay="false"
          :key="vimeoPlayerKey"
          @error="vimeoPlayerError"
          @ready="player => $emit('vimeoPlayerReady', player)"
          @loaded="(data, player) => $emit('vimeoPlayerLoaded', { data, player })"
          @ended="(data, player) => $emit('vimeoPlayerEnded', { data, player })"
          @pause="(data, player) => $emit('vimeoPlayerPaused', { data, player })"
          @timeupdate="
            (data, player) => $emit('vimeoPlayerTimeupdate', { data, player })
          "
          @seeking="
            (data, player) => $emit('vimeoPlayerSeeking', { data, player })
          "
          @seeked="(data, player) => $emit('vimeoPlayerSeeked', { data, player })"
          @durationchange="
            (data, player) =>
              $emit('vimeoPlayerDurationChanged', { data, player })
          "
        ></vueVimeoPlayer>
      </ClientOnly>
    </div>

    <!-- <HMFixedAspectRatioContainer v-if="type == 'youtube'">
      <ScriptYouTubePlayer
        :video-id="src"
      >
        <template #awaitingLoad>
          <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 48px; width: 68px;">
            <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00" /><path d="M 45,24 27,14 27,34" fill="#fff" /></svg>
          </div>
        </template>
      </ScriptYouTubePlayer> 
    </HMFixedAspectRatioContainer> -->
  <div v-if="type == 'youtube'" class="w-100 d-print-none">
    <ScriptYouTubePlayer
      :video-id="src"
      class="mw-100 w-100"
      style="aspect-ratio: 16 / 9;"
    >
      <template #awaitingLoad>
        <div style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); height: 48px; width: 68px;">
          <svg height="100%" version="1.1" viewBox="0 0 68 48" width="100%"><path d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z" fill="#f00" /><path d="M 45,24 27,14 27,34" fill="#fff" /></svg>
        </div>
      </template>
    </ScriptYouTubePlayer>
  </div>
    <div class="w-100 d-print-none" v-if="type == 'loom' && loomEmbed">
      <div v-html="loomEmbed"></div>
    </div>
    <div class="w-100 d-print-none" v-if="type == 'wistia' && wistiaEmbed">
      <div v-html="wistiaEmbed"></div>
    </div>
    <div class="w-100 d-print-none" v-if="type == 'videoask'">
      <iframe
        :src="file.video_url"
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
        width="100%"
        height="600px"
        style="border: none; border-radius: 24px"
      >
      </iframe>
    </div>
    <div class="w-100 d-print-none" v-if="type == 'vadootv'">
      <div>
        <div
          style="
            left: 0;
            width: 100%;
            height: 0;
            position: relative;
            padding-bottom: 56.25%;
          "
        >
          <figure
            style="
              left: 0;
              width: 100%;
              height: 0;
              position: relative;
              padding-bottom: 56.25%;
              margin-block-end: 0;
              margin-block-start: 0;
              margin-inline-start: 0;
              margin-inline-end: 0;
            "
          >
            <iframe
              :src="`https://api.vadoo.tv/iframe_test?id=${file.video_url}`"
              scrolling="no"
              style="
                border: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                overflow: hidden;
                border-radius: 5px;
              "
              allowfullscreen="1"
              allow="autoplay"
            ></iframe>
          </figure>
        </div>
      </div>
    </div>
    <div class="w-100 d-print-none" v-if="type == 'adilo'">
      <div>
        <div
          style="
            left: 0;
            width: 100%;
            height: 0;
            position: relative;
            padding-bottom: 56.25%;
          "
        >
          <figure
            style="
              left: 0;
              width: 100%;
              height: 0;
              position: relative;
              padding-bottom: 56.25%;
              margin-block-end: 0;
              margin-block-start: 0;
              margin-inline-start: 0;
              margin-inline-end: 0;
            "
          >
            <iframe
              :src="`https://adilo.bigcommand.com/watch/${file.video_url}`"
              scrolling="no"
              style="
                border: 0;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                position: absolute;
                overflow: hidden;
                border-radius: 5px;
              "
              allowfullscreen="1"
              allow="autoplay"
              allowtransparency="true"
            ></iframe>
          </figure>
        </div>
      </div>
    </div>
    <div class="w-100 d-print-none" v-show="type == 'typeform'">
      <div id="typeForm" ref="typeForm"></div>
    </div>
    <div v-if="type === 'image'">
      <img :src="src" class="img-fluid" />
    </div>
  </div>
</template>

<script>
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { getYoutubeIdFromURL } from '@/utils/parse-urls';
import * as loomEmbedSDK from '@loomhq/loom-embed';
import api from '@/api/media-libraries';
import axios from 'axios';
import moment from '@/services/moment';
import { createWidget } from '@typeform/embed';
import '@typeform/embed/build/css/widget.css';
import { mapState } from 'pinia';
import { useStudioStore } from '@/stores';
import uaParser from 'ua-parser-js';

export default {
  props: {
    file: {
      type: Object,
      default: null,
    },
    height: {
      type: String,
      default: '555px',
    },
    width: {
      type: String,
      default: '555px',
    },
    checkIfVideoInProgress: {
      type: Boolean,
      default: false,
    },
    videoProcessingText: {
      type: String,
      default: null,
    },
    ignorePlaybackStartStop: {
      type: Boolean,
      default: false,
    },
    preload: {
      type: String,
      default: 'metadata',
    },
  },
  components: { vueVimeoPlayer },
  data() {
    return {
      videoInProgress: false,
      videoUploadFailed: false,
      getFileInterval: false,
      vimeoPlayerKey: 0, // used to re-init the video player if there is an error
      vimeoPlayerReloads: 0, // allow a coupleo of reloads
      loomEmbed: null,
      wistiaEmbed: null,
      parsedUa: null,
    };
  },
  computed: {
    ...mapState(useStudioStore, {
      studio: 'currentStudio',
    }),
    allowAudioDownloads() {
      return this.studio?.allow_audio_downloads;
    },
    allowRecordingDownloads() {
      return this.studio?.allow_recording_downloads;
    },
    type() {
      if (!this.file) return null;

      const { file_type, file_content, student_uploaded } = this.file;

      const docxFileTypes = [
        'vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
      ].join(' ');

      switch (file_content) {
        case 'typeform':
        case 'audio':
        case 'image':
        case 'text':
          return file_content;
        case 'application':
          if (docxFileTypes.includes(file_type)) return 'docx';
          else return file_type;
        case 'video':
          if (student_uploaded) return 'storage_video';

          return file_type;
      }

      return null;
    },
    playbackStartStopString() {
      if (this.ignorePlaybackStartStop) return '';
      return `#t=${this.file.playback_start},${this.file.playback_end}`;
    },
    src() {
      if (process.server) {
        return null
      }
      this.parsedUa = uaParser(window.navigator)
      if (!this.type) return null;
      switch (this.type) {
        case 'docx':
          return `https://view.officeapps.live.com/op/embed.aspx?src=${this.file.storage_url}`;
        case 'pdf':
          if (this.parsedUa.os.name == 'iOS') {
            return `https://drive.google.com/viewerng/viewer?embedded=true&url=${this.file.storage_url}`;
          } else {
            return this.file.storage_url;
          }
        case 'audio':
        case 'image':
        case 'storage_video':
          return this.file.storage_url;
        case 'recording':
          return `${this.file.video_url}${this.playbackStartStopString}`;
        case 'youtube':
          return getYoutubeIdFromURL(this.file.video_url);
        case 'vimeo':
          // return this.file?.video_url?.split('/').pop();
          return `https://player.vimeo.com/video/${this.file?.video_url
            ?.split('/')
            .pop()}`;
        default:
          return null;
      }
    },
    isVimeoUploadTimeout() {
      if (!this.file) return false;
      if (this.file.file_type !== 'vimeo') return false;
      return moment(this.file.uploaded).isBefore(moment().subtract(1, 'day'));
    },
  },
  methods: {
    preventContextMenu(e, prevent) {
      if (!prevent) return;
      try {
        if (!e) e = window?.event;
        if (
          (e.type && e.type == 'contextmenu') ||
          (e.button && e.button == 2) ||
          (e.which && e.which == 3)
        ) {
          e.preventDefault();
          return false;
        }
      } catch (error) {
        // ignore
      }
    },
    async getLoomEmbed() {
      const loomData = await loomEmbedSDK.oembed(this.file.video_url);
      this.loomEmbed = loomData.html;
    },
    async getWistiaEmbed() {
      const videoUrl = encodeURIComponent(this.file.video_url);
      const res = await axios.get(
        `https://fast.wistia.com/oembed?url=${videoUrl}`
      );
      this.wistiaEmbed = res.data?.html;
    },
    renderTypeForm() {
      createWidget(this.file.file_name, {
        container: this.$refs['typeForm'],
      });
    },
    async getIsFileProcessing({ id, video_url }) {
      try {
        const videoUrl = encodeURIComponent(
          `https://player.vimeo.com/video/${video_url.split('/').pop()}`
        );
        const res = await axios.get(
          `https://vimeo.com/api/oembed.json?url=${videoUrl}&cachebuster=${new Date().getTime()}`
        );
        await api.getFile(id);
        return false;
      } catch (error) {
        return true;
      }
    },
    vimeoPlayerError(error) {
      console.log('vimeoPlayerError: ', error);
      // dont try to reload for errors like not embeddable
      if (error.message && error.message.indexOf('not embeddable') !== -1)
        return;
      // dont try to reload for errors where video is pwd protected and metadata is not available
      if (error.message && error.method.indexOf('appendVideoMetadata') !== -1)
        return;
      if (this.vimeoPlayerReloads >= 3) {
        // no more retries
        return;
      }
      setTimeout(() => {
        this.vimeoPlayerKey = new Date().getTime();
        this.vimeoPlayerReloads += 1;
      }, 200);
    },
  },
  mounted() {
    if (this.type === 'text' && this.file.content_raw) {
      const wrapper = this.$refs.rawContent;
      wrapper.innerHTML = '';
      const range = document.createRange();
      range.setStart(wrapper, 0);
      wrapper.append(range.createContextualFragment(this.file.content));
    }
  },
  watch: {
    file: {
      async handler(file, preFile) {
        console.log('this.type', this.type);
        if (this.type === 'loom') {
          this.getLoomEmbed();
        }
        if (this.type === 'wistia') {
          this.getWistiaEmbed();
        }
        if (this.type === 'typeform') {
          this.$nextTick(() => {
            this.renderTypeForm();
          });
        }
        clearInterval(this.getFileInterval);
        this.videoInProgress = false;
        if (!this.checkIfVideoInProgress) return;
        if (file?.file_type === 'vimeo') {
          if (preFile) {
            // Reload vimeo player when data is changed
            this.vimeoPlayerKey = new Date().getTime();
          }
          try {
            const isProcessing = await this.getIsFileProcessing(file);
            if (!isProcessing) return;
            this.videoInProgress = true;
            this.videoUploadFailed = false;
            this.getFileInterval = setInterval(async () => {
              const isFileProcessing = await this.getIsFileProcessing(file);
              if (isFileProcessing) {
                if (this.isVimeoUploadTimeout) {
                  this.videoUploadFailed = true;
                  clearInterval(this.getFileInterval);
                }
                this.videoInProgress = true;
              } else {
                clearInterval(this.getFileInterval);
                setTimeout(() => {
                  this.videoInProgress = false;
                }, 3000);
              }
            }, 10000);
          } catch (error) {
            this.videoInProgress = false;
            this.$notify({
              group: 'app',
              type: 'error',
              text: 'There was a problem with upload, please try again',
              duration: -1, // stay ON
            });
          }
        }
      },
      immediate: true,
    },
  },
  beforeUnmount() {
    clearInterval(this.getFileInterval);
  },
};
</script>

<style lang="scss" scoped>
@import '@/styles/components-variables.scss';

.processing {
  padding: 1.5rem 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: $light-gray;
  border-radius: $border-radius;
  color: $dark-gray;
}

.spinner {
  color: $medium-gray;
}

.soft-16-9 {
  padding-top: 56.25%;
}

:deep(#vimeo-player iframe) {
  width: 100%;
  height: 100%;
}

#typeForm {
  height: 650px;
}
</style>
